<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
import { format } from "date-fns";
//import router from "@/router/index.js";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: "liste des abonnés",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "liste",
          active: true,
        },
      ],
      tyMessage: [
        {
          name: "Notification",
          value: 0,
        },
        {
          name: "Mail",
          value: 1,
        },
        {
          name: "Notification + Mail",
          value: 2,
        },
      ],
      messageSelect: null,
      messageContent: [
        {
          sujet: "",
          message: "",
          userId: "",
        },
      ],
      value: null,
      value1: null,
      options: ["Sexe", "Taille", "Vehicule", "je vois"],
      idUser: 0,
      dataUser: [],
      dataUserD: [],
      nbreUser: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      abonnees: [],
      abonneesNonActif: [],
      fields: [
        { key: "username", sortable: true, label: "Nom Agence" },
        { key: "tel", sortable: true, label: "Contact" },
        { key: "email", sortable: true, label: "Email" },
        { key: "statut", sortable: true, label: "Statut" },
        { key: "datedebut", sortable: true, label: "Date début paiement" },
        { key: "datefin", sortable: true, label: "Date Fin paiement" },
        { key: "action", label: "Actions" },
      ],
      showDisable: false,
      showDelete: false,
      showEdit: false,
      showSucess: false,
      showEchec: false,
      userToUse: 0,
      infoUser: {
        firstname: "",
        lastname: "",
        boitepostale: "",
        profession: "",
        birthday: "",
        adresse: "",
        ville: "",
        pays: "",
        tel: "",
        email: "",
      },
      showMessage: false,
      editor: ClassicEditor,
      editorData: "",
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDataAbonnement: [],
      recherche: "",
      parBon: 500,
      totalAbonneInactif:0
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      //Chargement des données
      const data = await apiRequest(
        "GET",
        "admin/rmo/abonnement",
        undefined,
        false
      );
      if (data && data.data) {
        console.log("data.data:", data.data);
        this.totalValue = data.data.totalAbonnement;
        this.totalValue = data.data.totalAbonnement;

        const formattedTable = data.data.abonnements.map((abonnement) => {
          return {
            username: abonnement.agence.designation,
            tel: abonnement.agence.tel,
            email: abonnement.agence.email,
            statut: abonnement.statut,
            datedebut: format(
              new Date(abonnement.debutAbonnement),
              "dd-MM-yyyy hh:mm:ss"
            ),
            datefin: format(
              new Date(abonnement.finAbonnement),
              "dd-MM-yyyy hh:mm:ss"
            ),
          };
        });
        this.abonnees = formattedTable;
        console.log("data", data.data);
      }

      const dataD = await apiRequest(
        "GET",
        "admin/users-inactif",
        undefined,
        false
      );
      if (dataD && dataD.data) {
        /*const formattedTableD = dataD.data.map((user) => {
              return {
               id: user.id,
              username: user.lastname[0]==undefined ? "Nom Indéfini" : user.lastname[0] + ". " + user.firstname==undefined ? "Prénom Indéfini" : user.firstname,
              firstname:user.firstname,
              lastname:user.lastname,
              tel: user.tel,
              trajet: user.nombreTrajet,
              dateIns: format(
                new Date(user.dateCreation),
                "dd-MM-yyyy hh:mm:ss"
              ),
              badge: user.userValidated,
              statut: user.active ? "Actif" : "Suspendu",
            };
          });*/

        const dataD = await apiRequest(
          "GET",
          "admin/users-inactif",
          undefined,
          false
        );
        if (dataD && dataD.data) {
          const formattedTableD = dataD.data.map((user) => {
            return {
              id: user.id,
              username:
                user.lastname[0] == undefined
                  ? "Nom Indéfini"
                  : user.lastname[0] + ". " + user.firstname == undefined
                  ? "Prénom Indéfini"
                  : user.firstname,
              tel: user.tel,
              trajet: user.nombreTrajet,
              dateIns: format(
                new Date(user.dateCreation),
                "dd-MM-yyyy hh:mm:ss"
              ),
              badge: user.userValidated,
              statut: user.active ? "Actif" : "Suspendu",
            };
          });

          this.dataUserD = formattedTableD;
        }
        // Set the initial number of items
        this.totalAbonneInactif = this.dataUserD.length;
   
      }
    },
    async addMessage() {
      this.showMessage = false;
      const data = await apiRequest(
        "POST",
        "admin/message-user",
        {
          idUser: this.messageContent.userId,
          typeMessage: this.messageSelect.value,
          subject: this.messageContent.sujet,
          text: this.messageContent.message,
        },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.init();
        console.log(data);
        this.showSucess = true;
      } else {
        this.showEchec = true;
      }
    },
    EditModal() {
      this.submitted = false;
      this.showEdit = false;
    },
    editClick(row) {
      this.showEdit = true;
      console.log("LAST NAME: ", row);
      this.infoUser.lastname = row.lastname;
      this.infoUser.firstname = row.firstname;
      console.log("LAST NAME: ", row.firstname);
    },
    desactiverUser(row) {
      this.userToUse = row.id;
      //console.log('userToDel:', this.userToUse)
      this.showDisable = true;
    },
    async desactiveUser() {
      this.showDisable = false;
      this.position();
      const data = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.userToUse },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.init();
      }
    },
    messageShow(row) {
      this.showMessage = true;
      this.messageContent.userId = row.id;
      console.log(row.id);
    },
    async activeUser(row) {
      this.userToUse = row.id;
      this.position();
      const data = await apiRequest(
        "POST",
        "admin/activer-user",
        { idUser: this.userToUse },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.init();
      }
    },
    deleteUser(row) {
      this.userToUse = row.id;
      //console.log('userToDel:', this.userToUse)
      this.showDelete = true;
    },
    async deletUser() {
      this.showDelete = false;
      this.position();
      const data = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.userToUse },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.init();
      }
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    /* goToDetail(row) {
          console.log("row:", row);
          router.push(`/user/user_info/${row.id}`);
          //router.push('/user/user_info/'+row.id)
        }, */
    hideRow(row) {
      if (row.clicked == true) {
        console.log("test");
      }
    },

    async actif(page, limit) {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/rmo/abonnement?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        const formattedTable = data.data.abonnements.map((abonnement) => {
          return {
            username: abonnement.agence.designation,
            tel: abonnement.agence.tel,
            email: abonnement.agence.email,
            statut: abonnement.statut,
            datedebut: format(
              new Date(abonnement.debutAbonnement),
              "dd-MM-yyyy hh:mm:ss"
            ),
            datefin: format(
              new Date(abonnement.finAbonnement),
              "dd-MM-yyyy hh:mm:ss"
            ),
          };
        });
        this.newDataAbonnement = formattedTable;
    
        this.totalRows = formattedTable.length;
        this.userChargeLoader = false;
      }
    },

    async userSearch() {
      console.log(this.recherche);
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "GET",
        "admin/rmo/abonnement?mots=" + this.recherche,

        undefined,
        false
      );
      if (dataSearch && dataSearch.data) {
        const formattedTable = dataSearch.data.abonnements.map((abonnement) => {
          return {
            username: abonnement.agence.designation,
            tel: abonnement.agence.tel,
            email: abonnement.agence.email,
            statut: abonnement.statut,
            datedebut: format(
              new Date(abonnement.debutAbonnement),
              "dd-MM-yyyy hh:mm:ss"
            ),
            datefin: format(
              new Date(abonnement.finAbonnement),
              "dd-MM-yyyy hh:mm:ss"
            ),
          };
        });
        this.newDataAbonnement = formattedTable;

        this.userChargeLoader = false;
        this.totalRows = formattedTable.length;
      }
      },
      decrement() {
      this.userChargeLoader = true;

      this.startValue -= this.parBon;
      this.endValue -= this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += this.parBon;
      this.endValue += this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - this.parBon;
      this.endValue = this.totalValue;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    }
    },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="containerDot" v-if="abonnees.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body pt-0">
           
            <div class="row d-flex justify-content-between align-items-center p-2">
              <h4 class="card-title mt-4">
              <p>Total des abonnés {{ totalValue }}</p>
            </h4>
              <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == parBon"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Liste des abonnés</a>
                </template>
                <div
                  class="row d-flex justify-content-between align-items-center mt-4"
                >
                  <div class="p-3 h-10">
                  
                  </div>

                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="filter"
                        @keyup.enter="userSearch"
                      />
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="newDataAbonnement.length !=0?newDataAbonnement: abonnees"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="hideRow(row)"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="text-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'PENDING',
                          'badge-soft-success': `${row.value}` === 'PAYER',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <!-- <a
                            href="javascript:void(0);"
                            class="text-primary"
                            v-b-tooltip.hover
                            @click="editClick(row.item)"
                            title="Editer"
                          >
                            <i class="mdi mdi-pencil font-size-18"></i>
                          </a> -->
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        @click="desactiverUser(row.item)"
                        title="Désactiver"
                      >
                        <i class="mdi mdi-close font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        @click="messageShow(row.item)"
                        v-b-tooltip.hover
                        title="Message"
                      >
                        <i class="fas fa-envelope"></i>
                      </a>
                    </template>
                    <template v-slot:cell(tel)="row" style="text-align: center">
                      <a
                        :href="'https://wa.me/' + row.value"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == parBon"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active"
                    >Liste des abonnés désactivés</a
                  >
                </template>
                <div
                  class="row d-flex justify-content-between align-items-center mt-4"
                >
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>

                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="filter"
                      />
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="abonneesNonActif"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="hideRow(row)"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="text-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Non actif',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <!-- <a
                            href="javascript:void(0);"
                            class="text-primary"
                            v-b-tooltip.hover
                            @click="editClick(row.item)"
                            title="Editer"
                          >
                            <i class="mdi mdi-pencil font-size-18"></i>
                          </a> -->
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="desactiverUser(row.item)"
                        title="activer"
                      >
                        <i class="mdi mdi-check font-size-18"></i>
                      </a>
                      <!-- <a
                            href="javascript:void(0);"
                            class="text-success"
                            @click="messageShow(row.item)"
                            v-b-tooltip.hover
                            title="Message"
                          >
                            <i class="fas fa-envelope"></i>
                          </a> -->
                    </template>
                    <template v-slot:cell(tel)="row" style="text-align: center">
                      <a
                        :href="'https://wa.me/' + row.value"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
             <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalAbonneInactif"
                      :per-page="perPage"
                      :aria-controls="trajet"
                      :rows="abonneesNonActif"
                    ></b-pagination>
                  </ul>
                </div>
                   
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDisable"
      title="Voulez-vous vraiment suspendre cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactiveUser"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment supprimer cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deletUser"
          >Oui</b-button
        >
      </div>
    </b-modal>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucess"
      title="Message envoyé avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSucess = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchec"
      title="L'envoie a échoué"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchec = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Editer utilisateur"
      title-class="font-18"
      hide-footer
    >
      <form
        class="form-horizontal"
        role="form"
        method="post"
        @submit.prevent="EditSubmit"
      >
        <div class="row">
          <div class="col-md-4 mb-3">
            <label for="lastname">Nom</label>
            <input
              type="text"
              class="form-control py-4"
              id="lastname"
              placeholder=""
              v-model="infoUser.lastname"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Prénom</label>
            <input
              type="text"
              class="form-control py-4"
              id="firstname"
              placeholder=""
              v-model="infoUser.firstname"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Boîte postale</label>
            <input
              type="text"
              class="form-control py-4"
              id="boitepostale"
              placeholder=""
              v-model="infoUser.boitepostale"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Profession</label>
            <input
              type="text"
              class="form-control py-4"
              id="profession"
              placeholder=""
              v-model="infoUser.profession"
            />
          </div>
          <div class="col-md-4 mb-3">
            <div class="mb-3">
              <label for="birthday">Date de naissance</label>
              <date-picker id="birthday" type="date"></date-picker>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="lastname">Adresse</label>
            <input type="text" class="form-control py-4" placeholder="" />
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Ville</label>
            <input
              type="text"
              class="form-control py-4"
              id="firstname3"
              placeholder=""
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Pays</label>
            <input
              type="text"
              class="form-control py-4"
              id="firstname4"
              placeholder=""
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="lastname">Téléphone</label>
            <input
              type="text"
              class="form-control py-4"
              id="lastname5"
              placeholder=""
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Email</label>
            <input
              type="text"
              class="form-control py-4"
              id="firstname7"
              placeholder=""
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Mot de passe</label>
            <input
              type="text"
              class="form-control py-4"
              id="firstname6"
              placeholder=""
            />
          </div>
        </div>
        <button class="btn btn-success float-right" type="submit">
          Modifier
        </button>
      </form>
    </b-modal>

    <!-- ENVOYER LE MESSAGE-->
    <b-modal v-model="showMessage" title="Nouveau Message" centered>
      <form>
        <div class="form-group">
          <label class="control-label">Choisir le type de message:</label>
          <multiselect
            v-model="messageSelect"
            :options="tyMessage"
            label="name"
            track-by="name"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Sujet"
            v-model="messageContent.sujet"
          />
        </div>
        <div class="form-group">
          <ckeditor
            v-model="messageContent.message"
            :editor="editor"
            aria-placeholder="Votre Message"
          ></ckeditor>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->
  </Layout>
</template>
<style>
thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3AAA35;
  border-color: #252b3b;
}

.nav-tabs .nav-link {
   background: #dc143c;
   transition: all .5s;
 } 
 .nav-tabs .nav-link a{
  color: #fff ;
} 

.text-red{
  color:#dc143c;
}  .nav-tabs .nav-link a{
  color: #fff!important ;
}

.text-green{
  color:#3AAA35;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}
.nav-tabs-custom .nav-item .nav-link.active a {
  color: blue;
  font-weight: 600 !important;
  vertical-align: middle;
}
.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35;
  border-bottom: 1px solid blue;
}
.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
